<template>
  <div class="about-us-page eats-page mahalla">
    <section class="section-food">
      <div class="container">
        <div class="section-food-header">
          <div class="row justify-content-between">
            <div class="col-md-6 col-sm-12">
              <div class="text-lg">
                {{ $t("Joylashgan hududingizdagi taomlar") }}
              </div>
              <div class="text-sm">
                {{ $t("text for subfilter, some text") }}
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="section-food-selects">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select">
                      <div class="custom-select">
                        <select
                          v-model="regionId"
                          @change="$store.dispatch('getDistricts', regionId)"
                        >
                          <option value="" disabled>
                            {{ $t("Regionni tanlang") }}
                          </option>
                          <option
                            v-for="region in regions"
                            :key="region.id"
                            :value="region.id"
                          >
                            {{ region.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select my-select-last">
                      <div class="custom-select">
                        <select
                          v-model="districtId"
                          @change="
                            $store.dispatch('filterOrgDis', {
                              regionId: regionId,
                              districtId: districtId,
                            })
                          "
                        >
                          <option value="" selected disabled>
                            {{ $t("Shaharni tanlang") }}
                          </option>
                          <option
                            v-for="district in districts"
                            :key="district.id"
                            :value="district.id"
                          >
                            {{ district.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="food-menu">
            <ul class="bottom-border" v-if="regions.length > 0">
              <li>
                <a href="#!" v-if="regionId !== ''">{{
                  regions.find((el) => el.id == regionId).name
                }}</a>
              </li>
              <li>
                <a href="#!" v-if="districtId !== ''">{{
                  districts.find((el) => el.id == districtId).name
                }}</a>
              </li>
            </ul>
            <div class="myMap" :class="{'changeMapSize': fullmap}">
              <my-map></my-map>
              <div class="full-map" @click="fullmap=!fullmap">
                <img src="../../assets/images/full-hd-map.svg" alt>
              </div>
            </div>
            <section class="rek-menu">
              <div class="row" v-if="filteredOrgs.length > 0">
                <restoran-card
                  v-for="item in regionId == '' ? organizations : filteredOrgs"
                  :key="item.id"
                  :adress="item.address"
                  :legal_name="item.title"
                  :orgId="item.id"
                  :contact="item.contact"
                  :orgLogo="item.image"
                >
                </restoran-card>
              </div>
            </section>
          </div>
          <div
            class="more-button"
            v-if="filteredOrgs.length !== organizations.length"
          >
            <a @click="$store.dispatch('allOrganization')">{{
              $t("Barchasi")
            }}</a>
          </div>
        </div>
      </div>
    </section>
    <section class="au-first">
      <mini-cards></mini-cards>
    </section>
  </div>
</template>

<script>
import MiniCards from "../elements/MiniCards.vue";
import RestoranCard from "../elements/RestoranCard.vue";
import myMap from "../elements/Map.vue";
import { mapState } from "vuex";
export default {
  components: {
    MiniCards,
    RestoranCard,
    myMap,
  },
  data() {
    return {
      fullmap : false
    };
  },
  computed: {
    ...mapState(["organizations", "regions", "districts", "filteredOrgs"]),
    regionId: {
      get() {
        return this.$store.state.regionId;
      },
      set(value) {
        this.$store.commit("SET_REGION_ID", value);
      },
    },
    districtId: {
      get() {
        return this.$store.state.districtId;
      },
      set(value) {
        this.$store.commit("SET_DISTRICT_ID", value);
      },
    },
  },
  mounted() {},
};
</script>
<style>
.full-map {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 25px;
    right: 61px;
    z-index: 55555;
    background: #fff;
    padding: 10px;
    border-radius: 50%;
}
.full-map img {
   border-radius: 0;
}
.myMap.changeMapSize {
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  bottom: 0;
  height: 100vh;
  padding: 0;
  margin: 0;
  border-radius: 0;
  z-index: 5555;
}
.changeMapSize .vue2leaflet-map {
  border-radius:0 ;
}
.changeMapSize .full-map {
  top: 15px;
  right: 15px
  }
.myMap{
    width:auto;
  }
@media (max-width: 768px) {
  .myMap{
    padding: 10px;
    height: 400px;
  }
  .mahalla .rek-menu {
    margin-top: 70px;
  }
}
</style>